import { BOOKINGS_DEADLINES_TYPE_VALUE_GUEST_PAYMENT } from "../../../../constants/bookings";

/**
 * Retourne vrai si le type de l’échéance est un paiement client.
 * @param {object} param0
 * @param {import("../../../../types/Deadline").Deadline} param0.deadline
 * @returns {boolean}
 */
export default function bookingsDeadlinesIsTypeGuestPayment({ deadline }) {
  return BOOKINGS_DEADLINES_TYPE_VALUE_GUEST_PAYMENT === deadline.type;
}

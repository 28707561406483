// utils
import dayjs from "dayjs";

/**
 * Retourne le nombre de jours compris entre 2 dates.
 * @param {object} param0
 * @param {string} param0.from
 * @param {string} param0.to
 * @param {object} options
 * @param {boolean} [options.inclusive=false]
 * @returns {number}
 */
export default function dateGetDaysCount({ from, to }, options = {}) {
  const { inclusive = false } = options;

  return (
    Math.round(Math.abs(dayjs(from).diff(dayjs(to), "days"))) +
    (inclusive ? 1 : 0)
  );
}

// constants
import { BOOKINGS_STATE_VALUE_CANCELLED } from "../../../constants/bookings";

/**
 * Retourne vrai si la réservation est en état annulé.
 * @param {object} param0
 * @param {import("../../../types/CartBooking").CartBookingBase} param0.booking
 * @returns {boolean}
 */
export default function bookingsIsStateCancelled({ booking }) {
  return BOOKINGS_STATE_VALUE_CANCELLED === booking.state;
}

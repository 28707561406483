// constants
import { BOOKINGS_STATE_VALUE_DRAFT } from "../../../constants/bookings";

/**
 * Retourne vrai si la réservation est en état de brouillon.
 * @param {object} param0
 * @param {import("../../../types/CartBooking").CartBookingBase} param0.booking
 * @returns {boolean}
 */
export default function bookingsIsStateDraft({ booking }) {
  return BOOKINGS_STATE_VALUE_DRAFT === booking.state;
}

import { CARTS_BOOKINGS_WORKFLOW_VALUE_PARTNER } from "../../../constants/bookings";

/**
 * Détermine si une réservation est en workflow partenaire
 * @param {object} param0
 * @param {import("../../../types/CartBooking").CartBookingBase} param0.booking
 * @returns {boolean}
 */

export default function isWorkflowPartner({ booking }) {
  return booking?.workflow === CARTS_BOOKINGS_WORKFLOW_VALUE_PARTNER;
}

/**
 * Retourne le reste à payer d’une échéance.
 * @param {object} param0
 * @param {import("../../../../types/Deadline").Deadline} param0.deadline
 */
export default function bookingsDeadlinesGetRest({ deadline }) {
  return (
    deadline.amount -
    (deadline.payments ?? []).reduce(
      (payed, payment) => payed + payment.amount,
      0,
    )
  );
}

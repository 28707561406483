// constants
import {
  PLANS_ALIAS_VALUE_EXPERT,
  PLANS_ALIAS_VALUE_EXPERT_LEGACY,
} from "../../../../constants/plans";

/**
 * Retourne vrai si le plan donné vaut `expert`.
 * @param {object} param0
 * @param {string} param0.alias
 * @returns {boolean}
 */
export default function plansIsAliasExpert({ alias }) {
  return (
    PLANS_ALIAS_VALUE_EXPERT === alias ||
    PLANS_ALIAS_VALUE_EXPERT_LEGACY === alias
  );
}

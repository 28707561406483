/**
 * Retourne le nombre total de participant à un séjour.
 * @param {object} param0
 * @param {{ adults: number, children: number}} param0.booking
 * @returns {number}
 */
export default function bookingsGetNumberOfPersons({ booking }) {
  const { adults, children } = booking;

  let numberOfPersons = 0;
  if (adults) {
    numberOfPersons += adults;
  }

  if (children) {
    numberOfPersons += children;
  }

  return numberOfPersons;
}

import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

/**
 *
 * @param {import("../../../types/RoomService").RoomService} service
 * @param {boolean} isCharge
 * @returns {boolean}
 */
function chargeAllowed(service, isCharge) {
  return isCharge ? isCharge : !service.is_charge;
}

/**
 * Permet de terminer si on peut afficher le service (charge ou non) selon la date de checkin
 * les roomServices peuvent être conditionnéées par des dates d'applications
 * @param {object} param0
 * @param {Array<import("../../../types/RoomService").RoomService>} param0.serviceList
 * @param {Array<import("../../../types/CartBooking").BookingService>} param0.bookingServiceList
 * @param {string} param0.date
 * @param {boolean} [param0.isCharge=false]
 * @returns {Array<import("../../../types/RoomService").RoomService>}
 */
export default function bookingsIsServicesIsAvailableForPeriod({
  serviceList,
  date,
  bookingServiceList,
  isCharge = false,
}) {
  const now = dayjs().startOf("day");
  const _date = dayjs(date).startOf("day");

  return serviceList
    .filter(
      (roomService) =>
        bookingServiceList.every(
          (bookingService) =>
            String(bookingService.service_id) !== String(roomService.id),
        ) && chargeAllowed(roomService, isCharge),
    )
    .filter(
      (roomService) =>
        !roomService.is_restricted_by_dates ||
        (roomService.availability_periods ?? []).some((period) => {
          const from = now
            .set("date", period.from_day)
            .set("month", period.from_month - 1);

          const to = now
            .set("date", period.to_day)
            .set("month", period.to_month - 1);

          /**
           * Si to < from (ex: From: 1 octobre, To: 1 mars)
           * On recherche entre from - 1 year et to puis entre from et to + 1 year
           */

          if (to.isBefore(from)) {
            return (
              (_date.isSameOrAfter(from.subtract(1, "year")) &&
                _date.isSameOrBefore(to)) ||
              (_date.isSameOrAfter(from) &&
                _date.isSameOrBefore(to.add(1, "year")))
            );
          }

          return _date.isSameOrAfter(from) && _date.isSameOrBefore(to);
        }),
    );
}

import { BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_VALUE_PENDING } from "../../../constants/bookings";

/**
 * Retourne vrai si l’état de la confirmation propriétaire d’une réservation est en attente.
 * @param {object} param0
 * @param {import("../../../types/CartBooking").CartBookingBase} param0.booking
 * @returns {boolean}
 */
const bookingsIsOwnerAcknowledgmentStatePending = ({ booking }) =>
  Boolean(booking.owner_acknowledgment) &&
  BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_VALUE_PENDING ===
    booking.owner_acknowledgment_state;

export default bookingsIsOwnerAcknowledgmentStatePending;

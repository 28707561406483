/* Tri */
export const PLANS_SORT_VALUE_CREATED_ASC = "created_asc";
export const PLANS_SORT_VALUE_CREATED_DESC = "created_desc";

export const plansSortMessage = defineMessage({
  defaultMessage:
    "{sort, select, created_asc {Date de création croissante} created_desc {Date de création décroissante} other {{sort}}}",
});

export const PLANS_SORTS = {
  [PLANS_SORT_VALUE_CREATED_ASC]: {
    id: PLANS_SORT_VALUE_CREATED_ASC,
  },
  [PLANS_SORT_VALUE_CREATED_DESC]: {
    id: PLANS_SORT_VALUE_CREATED_DESC,
  },
};

export const PLANS_SORT_LIST = Object.values(PLANS_SORTS);

// Alias des plans.
export const PLANS_ALIAS_VALUE_LIBERTE = "liberte";
export const PLANS_ALIAS_VALUE_EXPERT = "expert";
export const PLANS_ALIAS_VALUE_COMPTE = "compte";
export const PLANS_ALIAS_VALUE_LIBERTY_LEGACY = "liberte_2020";
export const PLANS_ALIAS_VALUE_EXPERT_LEGACY = "expert_2020";

export const PLANS_ALIASES = {
  [PLANS_ALIAS_VALUE_LIBERTE]: {
    id: PLANS_ALIAS_VALUE_LIBERTE,
    color: "#9688CE",
  },
  [PLANS_ALIAS_VALUE_EXPERT]: {
    id: PLANS_ALIAS_VALUE_EXPERT,
    color: "#65B733",
  },
  [PLANS_ALIAS_VALUE_COMPTE]: {
    id: PLANS_ALIAS_VALUE_COMPTE,
    color: "#F85D23",
  },
};

export const PLANS_ALIAS_LIST = Object.values(PLANS_ALIASES);

// deps
import { defineMessage } from "react-intl";

export const plansAliasMessage = defineMessage({
  defaultMessage: `{alias, select,
    expert {Formule expert}
    liberte {Formule liberté}
    compte {Formule grand compte}
    other {{alias}}
  }`,
});

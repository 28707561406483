// constants
import { BOOKINGS_DEADLINES_STATE_VALUE_CANCELLED } from "../../../../constants/bookings";

/**
 * Retourne vrai si l’état de l’échéance est annulée.
 * @param {object} param0
 * @param {import("../../../../types/Deadline").Deadline} param0.deadline
 * @returns {boolean}
 */
const bookingsDeadlinesIsStateCancelled = ({ deadline }) =>
  BOOKINGS_DEADLINES_STATE_VALUE_CANCELLED === deadline.state;

export default bookingsDeadlinesIsStateCancelled;

// constants
import { CARTS_BOOKINGS_WORKFLOW_VALUE_DIRECT } from "../../../constants/bookings";
/**
 * Détermine si une réservation est en workflow direct
 * @param {object} param0
 * @param {import("../../../types/CartBooking").CartBookingBase} param0.booking
 * @returns {boolean}
 */

function isWorkflowDirect({ booking }) {
  return booking?.workflow === CARTS_BOOKINGS_WORKFLOW_VALUE_DIRECT;
}

export default isWorkflowDirect;

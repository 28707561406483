// constants
import { BOOKINGS_STATE_VALUE_CONFIRMED } from "../../../constants/bookings";

/**
 * Retourne vrai si la réservation est en état confirmé.
 * @param {object} param0
 * @param {import("../../../types/CartBooking").CartBookingBase} param0.booking
 * @returns {boolean}
 */
export default function bookingsIsStateConfirmed({ booking }) {
  return BOOKINGS_STATE_VALUE_CONFIRMED === booking.state;
}

import dateGetDaysCount from "../../date/getDaysCount";
/**
 * Récupère le nombre de nuits entre la date d'entrée et la date de sortie.
 * @param {object} param0
 * @param {{ checkin: string, checkout: string}} param0.booking
 * @returns {number}
 */
export default function bookingsGetNumberOfNights({ booking }) {
  if (!booking.checkin || !booking.checkout) {
    return 0;
  }

  return dateGetDaysCount({
    from: booking.checkout,
    to: booking.checkin,
  });
}

// constants
import { BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_VALUE_REFUSED } from "../../../constants/bookings";

/**
 * Retourne vrai si l’état de la confirmation propriétaire d’une réservation est refusée.
 * @param {object} param0
 * @param {import("../../../types/CartBooking").CartBookingBase} param0.booking
 * @returns {boolean}
 */
export default function bookingsIsOwnerAcknowledgmentStateRefused({ booking }) {
  return (
    BOOKINGS_OWNER_ACKNOWLEDGMENT_STATE_VALUE_REFUSED ===
    booking.owner_acknowledgment_state
  );
}

// constants
import {
  BOOKINGS_STATE_VALUE_CANCELLED,
  BOOKINGS_CANCELLATION_REASON_VALUE_OTHER,
} from "../../../constants/bookings";
/**
 * Détermine si la réservation a été annulée pour une raison autre
 * On vérifie que le réservation est bien annulée (cancelled)
 * et que la cause de la raison autre
 */
/**
 * @param {object} param0
 * @param {import("../../../types/CartBooking").CartBooking} param0.booking
 * @returns {boolean}
 */
function bookingsHasBeenCancelledByOther({ booking }) {
  const state = booking.state;
  const cancellationReason = booking.cancellation_reason;
  return (
    state === BOOKINGS_STATE_VALUE_CANCELLED &&
    cancellationReason === BOOKINGS_CANCELLATION_REASON_VALUE_OTHER
  );
}

export default bookingsHasBeenCancelledByOther;

// constants
import { CARTS_BOOKINGS_WORKFLOW_VALUE_SYSTEM } from "../../../constants/bookings";
/**
 * Détermine si une réservation est en workflow system
 * @param {object} param0
 * @param {import("../../../types/CartBooking").CartBookingBase} param0.booking
 * @returns {boolean}
 */

function isWorkflowSystem({ booking }) {
  return booking?.workflow === CARTS_BOOKINGS_WORKFLOW_VALUE_SYSTEM;
}

export default isWorkflowSystem;

import bookingsDeadlinesIsStateSolded from "../deadlines/isStateSolded";
import bookingsDeadlinesIsTypeGuestPayment from "../deadlines/isTypeGuestPayment";
import bookingsDeadlinesIsCancelled from "../deadlines/isStateCancelled";
import bookingsDeadlinesGetRest from "../deadlines/getRest";

/**
 * Retourne le restant à payer d’une réservation.
 * @param {object} param0
 * @param {import("../../../types/CartBooking").CartBooking} param0.booking */
const bookingsGetPaymentRest = ({ booking }) => {
  return (booking.deadlines ?? [])
    .filter((deadline) => {
      return (
        bookingsDeadlinesIsTypeGuestPayment({ deadline }) &&
        !bookingsDeadlinesIsStateSolded({ deadline }) &&
        !bookingsDeadlinesIsCancelled({ deadline })
      );
    })
    .reduce((rest, deadline) => {
      return rest + bookingsDeadlinesGetRest({ deadline });
    }, 0);
};

export default bookingsGetPaymentRest;

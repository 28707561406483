/**
 * Détermine si le taxe de séjour doit être collecté par le propriétaire sur place
 * @param {object} param0
 * @param {import("../../../types/CartBooking").CartBooking} param0.booking
 * @returns {boolean}
 */
export default function bookingsShouldTouristTaxPaidByCustomer({ booking }) {
  if (booking.tourist_tax_amount === 0) {
    return false;
  }

  return !booking?.customer_amount_estimation?.tourist_tax_system ?? false;
}

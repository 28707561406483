import { BOOKINGS_DEADLINES_STATE_VALUE_SOLDED } from "../../../../constants/bookings";

/**
 * Retourne vrai si l’état de l’échéance est soldée.
 * @param {object} param0
 * @param {import("../../../../types/Deadline").Deadline} param0.deadline
 * @returns {boolean}
 */
const bookingsDeadlinesIsStateSolded = ({ deadline }) =>
  BOOKINGS_DEADLINES_STATE_VALUE_SOLDED === deadline.state;

export default bookingsDeadlinesIsStateSolded;
